<template>
  <div class="container-fluid content">
    <div class="content-container">
      <!-- Sidebar Content -->
      <div class="content-sidebar">
        <user-sidebar></user-sidebar>
      </div>

      <!-- Content Section -->
      <div class="content-section">
        <user-header></user-header>

        <div class="container">
          <!-- Tabs -->
          <div class="row tabs">
            <div class="col-6 notif-header"
                :class="currentNotif === 'admin' ? 'active' : ''"
                @click="toggleNotif('admin')">
              <p class="text-center mb-0">H3 Notifications</p>
            </div>
            <div class="col-6 notif-header"
                :class="currentNotif === 'activity' ? 'active' : ''"
                @click="toggleNotif('activity')">
              <p class="text-center mb-0">Activity Notifications</p>
            </div>
          </div>

          <div class="list">
            <transition name="component-fade" mode="out-in">
              <!-- H3 Notifications -->
              <div v-if="currentNotif === 'admin'">
                <div v-if="adminListNotifs">
                  <div class="admin-list"
                      v-for="(adminList, index) in adminListNotifs.slice(0, 30)"
                      :key="index"
                      :class="adminList.class_name"
                      @click="readAdminNotif(adminList)">
                    <p><strong>{{ adminList.title }}</strong></p>
                    <p class="notif-msg">{{ adminList.message }}</p>
                    <div class="icon-time">
                      <img class="notif-img"
                           src="../../assets/images/logo_icon.png" />
                      <p class="date">
                        {{ adminList.created | formatRelativeTime }}
                      </p>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <p class="no-list">No H3 Notifications</p>
                </div>
              </div>

              <!-- Activity Notifications -->
              <div v-else>
                <div v-if="activityNotifs">
                  <div class="activity-list"
                      v-for="activityList in activityNotifs.slice(0, 30)"
                      :key="activityList.activity_notif_id"
                      :class="activityList.status"
                      @click="readActivityNotif(activityList)">
                    <p><strong>{{ activityList.title }}</strong></p>
                    <p class="notif-msg">{{ activityList.message }}</p>
                    <img class="notif-img-2"
                        src="../../assets/images/new_school_icon.png" />
                    <p class="date">
                      {{ activityList.created | formatRelativeTime }}
                    </p>
                  </div>
                </div>
                <div v-else>
                  <p class="no-list">No Activity Notification</p>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <modals-container />

    <!-- Report Feedback -->
    <div class="feedback-wrapper" v-if="showReportForm">
      <report-feedback @close-report-feedback="showReportFeedback" />
    </div>
    <fab
      :position="'bottom-right'"
      :bg-color="'#eaa627'"
      :main-icon="'feedback'"
      :main-tooltip="'Report Feedback'"
      :enable-rotation="true"
      @click.native="showReportFeedback"
    />
  </div>
</template>

<script>
  const UserSidebar = () => import('@/components/shared/UserSidebar');
  const UserHeader = () => import('@/components/shared/UserHeader');
  const ReportFeedback = () => import('@/components/shared/ReportFeedback');

  import Fab from 'vue-fab';
  import FilterMixin from '@/mixins/FilterMixin';
  import { mapActions } from 'vuex';

  export default {
    name   : 'NotificationListAll',
    mixins : [
      FilterMixin,
    ],
    data() {
      return {
        adminListNotifs : this.$store.state.notification.adminListNotifs.data,
        activityNotifs  : this.$store.state.notification.activityNotifs.data,
        currentNotif    : 'admin',
        showReportForm  : false,
      }
    },
    components : {
      Fab,
      ReportFeedback,
      UserSidebar,
      UserHeader,
    },
    watch : {
      '$store.state.notification.adminListNotifs'() {
        this.adminListNotifs =
          this.$store.state.notification.adminListNotifs.data;
      },
      '$store.state.notification.activityNotifs'() {
        this.activityNotifs =
          this.$store.state.notification.activityNotifs.data;
      },
    },
    methods : {
      ...mapActions({
        'getAdminListNotifs' : 'notification/getAdminListNotifs',
        'getActivityNotifs'  : 'notification/getActivityNotifs',
      }),

      /**
       * Set Admin Notif as read
       * @param adminNotif
       */
      readAdminNotif(adminNotif) {
        if (adminNotif.class_name === 'unread') {
          this.$http.post('api/notification/admin/read', {
            adminNotifId : adminNotif.admin_notif_id,
          }).then(() => {
            // update admin list notifs
            this.getAdminListNotifs();
          });
        }

        // redirect
        this.$router.push({ path : adminNotif.website_redirect_url });
      },

      /**
       * Set Activity Notif as read
       * @param activityNotif
       */
      readActivityNotif(activityNotif) {
        if (activityNotif.status === 'unread') {
          this.$http.post('api/notification/activity/read', {
            adminNotifId : activityNotif.activity_notif_id,
          }).then(() => {
            this.getActivityNotifs();
          });
        }

        // redirect
        this.$router.push({ path : activityNotif.website_redirect_url });
      },

      /**
       * Toggle Notif
       * @param type
       */
      toggleNotif(type) {
        this.currentNotif = type;
      },

      /**
       * Toggle Report Feedback form
       */
      showReportFeedback() {
        this.showReportForm = !this.showReportForm;
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/notification/notification-list-all";
</style>

